<template>
  <div class="text-left pl-10">
    <v-row class="pb-4">
      <v-col>
        <TheMainHeader
          class="pt-10"
          :text="terminalStore.terminal?.name ?? t('missing')"
        />
        <div class="text-h5 text-primary py-2">
          {{ t("slotManagement.title") }} > {{ currentModule.name }}
        </div>
      </v-col>
      <v-col cols="3">
        <v-select
          v-model="currentModule"
          class="pt-10 pr-12"
          :label="t('slotManagement.select_module')"
          item-title="name"
          item-value="id"
          return-object
          variant="outlined"
          :items="mockData.modules"
        />
      </v-col>
    </v-row>

    <div class="pr-12">
      <ScheduleCalendarX
        :module="currentModule"
        :drawer="drawer"
        @selected-timeslots="updateSelectedTimeSlots"
      />
    </div>

    <div>
      <v-navigation-drawer
        v-model="drawer"
        @update:model-value="resetList"
        temporary
        location="right"
        :width="470"
      >
        <v-list-item class="font-weight-bold text-h5 py-3">
          {{ t("edit_timeslot") }}
        </v-list-item>

        <v-divider />

        <v-list-item>
          <span class="font-weight-bold">
            <div class="my-3">
              {{ terminalStore.terminal?.name ?? t("missing") }} |
              {{ currentModule.name }}
            </div>
            <v-divider
              v-if="currentSelectedTimeSlots.length > 1"
              class="my-2"
            />
            <span v-if="currentSelectedTimeSlots.length > 1">
              {{ t("slotManagement.multiple_timeslot_selected") }} ({{
                currentSelectedTimeSlots.length
              }})
            </span>
          </span>

          <v-list v-if="currentSelectedTimeSlots.length">
            <v-list-item
              v-for="(timeSlot, index) in showFullList
                ? currentSelectedTimeSlots
                : currentSelectedTimeSlots.slice(0, 2)"
              :key="timeSlot.id"
            >
              <v-list-item-title>
                <v-row>
                  <v-col cols="1" align-self="center">
                    <span>#{{ index + 1 }}</span>
                  </v-col>
                  <v-col cols="11">
                    <span>
                      {{ formatTimeSlot(timeSlot) }}
                      <div>
                        <span :class="`bg-${timeSlot.category} px-2 rounded`">
                          {{ timeSlot.capacity.currentNumber }} /
                          {{ timeSlot.capacity.maximum }}
                        </span>
                      </div>
                    </span>
                  </v-col>
                </v-row>

                <v-divider
                  v-if="
                    index <
                      (showFullList
                        ? currentSelectedTimeSlots.length - 1
                        : 1) && currentSelectedTimeSlots.length > 1
                  "
                  class="mt-2"
                />
              </v-list-item-title>
            </v-list-item>

            <div v-if="currentSelectedTimeSlots.length > 2" class="mt-4">
              <v-btn @click="showFullList = !showFullList" variant="tonal">
                {{
                  showFullList ? t("collapse_show_less") : t("expand_show_all")
                }}
              </v-btn>
            </div>
          </v-list>
        </v-list-item>

        <v-divider />

        <v-list density="compact" nav>
          <v-list-item>
            <span class="font-weight-bold">
              {{ t("slotManagement.slot_settings") }}
            </span>
          </v-list-item>
        </v-list>

        <v-switch
          v-model="status"
          :label="`${t('slotManagement.slot_status')}: ${status ? t('active') : t('inactive')}`"
          color="primary"
          hide-details
          class="pl-4"
        />

        <v-text-field
          v-if="currentSelectedTimeSlots.length"
          v-model="capacityInput"
          class="pa-4"
          variant="outlined"
          density="compact"
          :rules="[validatePositiveWholeNumber]"
          :label="t('slotManagement.slot_capacity')"
          @update:model-value="handleCapacityChange"
          type="number"
        />

        <template #append>
          <v-divider />

          <div class="text-right pa-4">
            <v-btn
              class="mr-2"
              variant="text"
              color="red"
              @click="cancelChanges"
            >
              {{ t("cancel") }}
            </v-btn>

            <v-btn color="primary" :disabled="!hasChanges" @click="saveChanges">
              {{ t("save_changes") }}
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import ScheduleCalendarX from "@/components/ScheduleCalendarX.vue";
import TheMainHeader from "@/components/TheMainHeader.vue";
import "@schedule-x/theme-default/dist/index.css";
import { useTerminalStore } from "@/store/useTerminalStore";
import TerminalData, {
  Module,
  TimeSlot,
} from "@/internal-models/terminalMockData";
import { format, parseISO } from "date-fns";
import { useSnackbarStore } from "@/store/useSnackbarStore";
import SnackbarType from "@/store/interfaces/snackbar-type";
import { cloneDeep } from "lodash";
import { useI18n } from "vue-i18n";

const terminalStore = useTerminalStore();
const snackbarStore = useSnackbarStore();
const { t } = useI18n();

const showFullList = ref(false);
const drawer = ref(false);
const currentSelectedTimeSlots = ref<TimeSlot[]>([]);
const tempSlots = ref<TimeSlot[]>([]);
const status = ref<boolean>(true);
const capacityInput = ref<string | number>("");

const mockData = generateMockData(
  new Date("2024-12-09"),
  new Date("2024-12-29"),
);

const currentModule = ref(mockData.modules[0]);

const updateSelectedTimeSlots = (timeSlots: TimeSlot[]) => {
  currentSelectedTimeSlots.value = timeSlots;
  tempSlots.value = cloneDeep(timeSlots);

  if (timeSlots.length === 1) {
    capacityInput.value = timeSlots[0].capacity.maximum;
  } else {
    const allSameCapacity = timeSlots.every(
      slot => slot.capacity.maximum === timeSlots[0].capacity.maximum,
    );

    if (allSameCapacity) {
      capacityInput.value = timeSlots[0].capacity.maximum;
    } else {
      capacityInput.value = "Mixed";
    }
  }
  drawer.value = !drawer.value;
};

const hasChanges = computed(() => {
  return (
    JSON.stringify(currentSelectedTimeSlots.value) !==
    JSON.stringify(tempSlots.value)
  );
});

const resetList = (drawerState: boolean) => {
  if (!drawerState) {
    currentSelectedTimeSlots.value = [];
    tempSlots.value = [];
    capacityInput.value = "";
  }
};

const formatTimeSlot = (timeSlot: TimeSlot): string => {
  const start = parseISO(timeSlot.slotBegin);
  const end = parseISO(timeSlot.slotEnd);
  const day = format(start, "EEEE do 'of' MMMM yyyy");
  const time = `${format(start, "HH:mm")} - ${format(end, "HH:mm")}`;
  return `${day} | ${time}`;
};

const toggleSlotStatus = () => {
  if (status.value) {
    tempSlots.value.forEach(slot => {
      slot.capacity.maximum = 50;
    });
  } else {
    tempSlots.value.forEach(slot => {
      slot.capacity.maximum = 0;
    });
  }
};

const canSaveChanges = computed(() => {
  return currentSelectedTimeSlots.value.every(slot => {
    const slotEndTime = new Date(slot.slotEnd).getTime();
    return slotEndTime >= Date.now();
  });
});

const validatePositiveWholeNumber = (value: number) => {
  if (!value) return true;
  const newValue = Number(value);
  const isNotNaN = !Number.isNaN(newValue);
  const isNonNegative = newValue >= 0;
  const isWholeNumber = Number.isInteger(newValue);

  return (
    (isNotNaN && isNonNegative && isWholeNumber) ||
    t("errors.only_positive_whole_numbers")
  );
};

const handleCapacityChange = () => {
  const newCapacity = Number(capacityInput.value);
  if (Number.isInteger(newCapacity)) {
    tempSlots.value.forEach(slot => {
      slot.capacity.maximum = newCapacity;
    });
  }
};

// Temporary function for mock data. This will be replaced with API calls
const saveChanges = () => {
  if (!canSaveChanges.value) {
    snackbarStore.showSnackbar({
      text: t("timeslot_in_the_past"),
      snackbarType: SnackbarType.ERROR,
    });
    return;
  }

  const moduleIndex = mockData.modules.findIndex(
    module => module.id === currentModule.value.id,
  );

  if (moduleIndex !== -1) {
    const module = mockData.modules[moduleIndex];

    module.timeSlots.forEach(slot => {
      const updatedSlot = tempSlots.value.find(s => s.id === slot.id);
      if (updatedSlot) {
        slot.capacity = { ...updatedSlot.capacity };
      }
    });
  }
  currentSelectedTimeSlots.value = [...tempSlots.value];
  drawer.value = false;
};

const cancelChanges = () => {
  tempSlots.value = JSON.parse(JSON.stringify(currentSelectedTimeSlots.value));
  drawer.value = false;
};

function generateMockData(startDate: Date, endDate: Date) {
  const modules = [
    {
      id: 1,
      name: "Module 1",
      openingHour: "06:00",
      closingHour: "22:00",
      defaultCapacity: 30,
    },
    {
      id: 2,
      name: "Module 2",
      openingHour: "06:00",
      closingHour: "22:00",
      defaultCapacity: 30,
    },
    {
      id: 3,
      name: "Module 3",
      openingHour: "06:00",
      closingHour: "22:00",
      defaultCapacity: 25,
    },
  ];

  const openingHours = { start: 6, end: 22 };
  const colors = {
    green: { min: 0, max: 50 },
    yellow: { min: 50, max: 80 },
    red: { min: 80, max: 100 },
  };

  const result: TerminalData = {
    modules: [],
  };

  const formatDateTime = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  let slotIdCounter = 1;

  const generateSlotsForDate = (date: Date, defaultCapacity: number) => {
    const slots = [];
    for (let hour = openingHours.start; hour < openingHours.end; hour++) {
      const slotBegin = new Date(date);
      slotBegin.setHours(hour, 0, 0, 0);

      const slotEnd = new Date(slotBegin);
      slotEnd.setMinutes(slotEnd.getMinutes() + 60);

      const percentageBooked =
        date.getDate() === 25 ? 0 : Math.floor(Math.random() * 101);
      let color = "green";
      if (
        percentageBooked > colors.green.max &&
        percentageBooked <= colors.yellow.max
      ) {
        color = "yellow";
      } else if (percentageBooked > colors.yellow.max) {
        color = "red";
      }

      slots.push({
        id: slotIdCounter++,
        slotBegin: formatDateTime(slotBegin),
        slotEnd: formatDateTime(slotEnd),
        capacity: {
          currentNumber: Math.floor((defaultCapacity * percentageBooked) / 100),
          maximum: defaultCapacity,
        },
        category: color,
      });
    }
    return slots;
  };

  const currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    modules.forEach(module => {
      let moduleData: Module | undefined = result.modules.find(
        m => m.id === module.id,
      );
      if (!moduleData) {
        moduleData = {
          id: module.id,
          name: module.name,
          timeSlots: [],
          openingHour: module.openingHour,
          closingHour: module.closingHour,
          slotLength: 50,
        };
        result.modules.push(moduleData);
      }
      moduleData.timeSlots.push(
        ...generateSlotsForDate(currentDate, module.defaultCapacity),
      );
    });

    currentDate.setDate(currentDate.getDate() + 1);
  }

  return result;
}

watch(status, () => {
  toggleSlotStatus();
});

watch(capacityInput, newValue => {
  if (newValue !== "Mixed") {
    handleCapacityChange();
  }
});
</script>

<style lang="css" scoped>
.v-navigation-drawer__scrim {
  position: fixed;
}
</style>
