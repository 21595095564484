<template>
  <div>
    <TheMainHeader
      class="text-left pl-10 pt-10"
      :text="terminalStore.terminal?.name ?? t('missing')"
    />
    <div class="d-flex text-left pl-10 pt-2 align-center">
      <span class="bg-greyish pa-2 rounded-lg">
        <v-icon
          :icon="`custom:${groupedItems[0]?.services?.[0]?.icon}`"
          :height="24"
          :width="24"
        />
      </span>
      <span class="text-h4 font-weight-medium text-primary ml-2">
        {{ t(groupedItems[0]?.services?.[0]?.name ?? "missing") }}
      </span>
    </div>

    <div>
      <v-container fluid class="pa-10">
        <v-row>
          <v-col xs="12" sm="12" md="7" lg="8" class="pr-8">
            <v-row>
              <v-col cols="12" class="text-h5 font-weight-bold">
                <div class="text-left">{{ t("statistics") }}</div>
              </v-col>

              <v-col sm="4" md="2" xl="2">
                <TimeRangeDropdown
                  :custom-range="customRange"
                  @change-range="changeRange"
                />
              </v-col>

              <v-spacer />

              <v-col sm="8" md="4" xl="4">
                <v-form v-model="valid">
                  <v-row>
                    <v-col cols="6">
                      <v-menu
                        transition="scale-transition"
                        :close-on-content-click="false"
                        offset="-20"
                      >
                        <template #activator="{ props }">
                          <v-text-field
                            v-bind="props"
                            :model-value="formatDateInput(startDate)"
                            readonly
                            bg-color="white"
                            variant="outlined"
                            density="comfortable"
                            hide-details="auto"
                            data-testid="test-startDate"
                            :rules="[dateNotEmpty]"
                            :placeholder="startDatePlaceHolder"
                            :label="t('startDate')"
                          />
                        </template>

                        <v-locale-provider :locale="locale">
                          <v-date-picker
                            v-model="startDate"
                            color="primary"
                            no-title
                            :no-data-text="t('no_data_available')"
                            :title="t('select_date')"
                            :allowed-dates="allowedDates"
                            :min="minDate"
                            @update:modelValue="startDateChange"
                          />
                        </v-locale-provider>
                      </v-menu>
                    </v-col>

                    <v-col cols="6">
                      <v-menu
                        transition="scale-transition"
                        :close-on-content-click="false"
                        offset="-20"
                      >
                        <template #activator="{ props }">
                          <v-text-field
                            v-bind="props"
                            :model-value="formatDateInput(endDate)"
                            readonly
                            bg-color="white"
                            hide-details="auto"
                            variant="outlined"
                            density="comfortable"
                            :rules="[dateNotEmpty]"
                            :placeholder="endDatePlaceHolder"
                            :label="t('endDate')"
                          />
                        </template>

                        <v-locale-provider :locale="locale">
                          <v-date-picker
                            v-model="endDate"
                            color="primary"
                            no-title
                            :title="t('select_date')"
                            :allowed-dates="allowedDates"
                            @update:modelValue="endDateChange"
                          />
                        </v-locale-provider>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
              <v-col sm="12">
                <TimeSeriesChart
                  v-if="chartData"
                  :data="chartData"
                  :loading="chartLoading"
                  :title="t('history')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col xs="12" sm="3" md="3">
                <TheStatisticValueBox
                  :statistic-value="statisticValues[statisticNames[0]]"
                />
              </v-col>
              <v-col xs="12" sm="3" md="3">
                <TheStatisticValueBox
                  :statistic-value="statisticValues[statisticNames[1]]"
                />
              </v-col>
              <v-col xs="12" sm="3" md="3">
                <TheStatisticValueBox
                  :statistic-value="statisticValues[statisticNames[2]]"
                />
              </v-col>
              <v-col xs="12" sm="3" md="3">
                <TheStatisticValueBox
                  :statistic-value="statisticValues[statisticNames[3]]"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col xs="12" sm="12" md="5" lg="4" class="pr-15">
            <v-row>
              <div class="text-h5 font-weight-bold mb-4">
                {{ t("modules") }}
              </div>
              <div
                v-for="mod in terminalData.modules"
                :key="mod.id"
                class="w-100 mb-4"
              >
                <v-card
                  variant="flat"
                  border="opacity-10 sm"
                  class="rounded-lg"
                >
                  <v-row class="pa-3">
                    <v-col>
                      <span class="d-flex font-weight-bold">
                        {{ mod.name }}
                      </span>
                      <span class="d-flex">
                        {{ getCurrentTimeSlotTime(mod.id) }}
                      </span>
                    </v-col>
                    <v-col>
                      <v-sheet
                        :color="`${getCurrentTimeSlotColor(mod.id)}-lighten-1`"
                        rounded="lg"
                        class="h-100 position-relative"
                      >
                        <span
                          class="position-absolute text-h6 text-white font-weight-bold text-center rounded-lg capacity-text"
                        >
                          {{ currentCapacity(mod.id) }}
                        </span>

                        <v-sheet
                          :color="getCurrentTimeSlotColor(mod.id)"
                          rounded="lg"
                          class="h-100"
                          :style="{ width: getCapacityWidth(mod.id) }"
                        />
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </v-row>

            <v-row class="d-flex">
              <div class="text-h5 font-weight-bold my-4">{{ t("apps") }}</div>
              <div
                v-for="service in filteredServices"
                :key="service.name"
                class="w-100 bg-white rounded-lg mb-2 border"
              >
                <v-list-item
                  class="rounded-lg cursor-pointer pl-3"
                  :to="service.pagePath"
                  :replace="true"
                  style="height: 4em"
                >
                  <template #prepend>
                    <div class="mr-2 bg-greyish pa-2 rounded-lg">
                      <v-icon
                        :icon="`custom:${service.icon}`"
                        :height="24"
                        :width="24"
                      />
                    </div>
                  </template>

                  <v-list-item-title
                    class="font-weight-medium text-left text-h6"
                  >
                    {{ t(service.name) }}
                  </v-list-item-title>

                  <template #append>
                    <v-icon
                      icon="mdi-chevron-right"
                      color="primary"
                      :height="24"
                      :width="24"
                    />
                  </template>
                </v-list-item>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script setup lang="ts">
// Components
import TheMainHeader from "@/components/TheMainHeader.vue";
import TimeSeriesChart from "@/components/TimeSeriesChart.vue";
import { TimeSeriesChartData } from "@/internal-models/charts";
import TheStatisticValueBox from "@/components/TheStatisticValueBox.vue";
// import ThePageCard from "@/components/ThePageCard.vue";
import TimeRangeDropdown from "@/views/terminal-operator/dashboard/TimeRangeDropdown.vue";

// Constants
// import getTerminalOperatorPageCards from "@/constants/terminal-operator-page-cards";
import TimeRange from "@/internal-models/time-range";
import Duration from "@/internal-models/enums/duration";
import {
  RangedTerminalStatisticsValueRequest,
  RangedTerminalStatisticsValueResponse,
  TimeSeriesResponse,
} from "@/services/client/generated";
import StatisticValue from "@/internal-models/statistic-value";
import {
  findBorderColor,
  getStatisticTypeFromStringForTerminal,
} from "@/utils/statistic-utils";
import getSidebarItems from "@/constants/sidebar-items";

// Utilities
import {
  format,
  compareAsc,
  parseISO,
  sub,
  startOfDay,
  endOfDay,
  formatISO,
} from "date-fns";

// Plugins
import i18n from "@/plugins/i18n";
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useTerminalStore } from "@/store/useTerminalStore";
import { useForwarderStore } from "@/store/useForwarderStore";
import { getViewModel } from "./terminal-dashboard-logic";
import { useI18n } from "vue-i18n";
import TerminalData from "@/internal-models/terminalMockData";

const viewModel = getViewModel();
const terminalStore = useTerminalStore();
const forwarderStore = useForwarderStore();
const { t, locale } = useI18n();

// Reactive state
const terminalData = ref<TerminalData>({
  modules: [
    {
      name: "Module 1",
      id: 1,
      slotLength: 15,
      openingHour: "08:00",
      closingHour: "18:00",
      timeSlots: [
        {
          id: 1,
          slotBegin: "2025-01-10 10:00",
          slotEnd: "2025-01-10 23:00",
          capacity: {
            currentNumber: 10,
            maximum: 50,
          },
          category: "green",
        },
      ],
    },
    {
      name: "Module 2",
      id: 2,
      slotLength: 30,
      openingHour: "08:00",
      closingHour: "18:00",
      timeSlots: [
        {
          id: 1,
          slotBegin: "2025-01-10 10:00",
          slotEnd: "2025-01-10 23:00",
          capacity: {
            currentNumber: 35,
            maximum: 50,
          },
          category: "yellow",
        },
      ],
    },
  ],
});
const groupedItems = ref(getSidebarItems());
const valid = ref(false);
const range = ref<TimeRange>({ duration: Duration.DAYS, amount: 1 });
const statisticValues = ref<Record<string, StatisticValue>>({});
const statisticNames = [
  "active_bookings",
  "completed_bookings",
  "canceled_bookings",
  "time_register_success_transhipment",
];
let intervalId: number | NodeJS.Timeout = -1;
const updateInterval = 1000 * 60;
const today = new Date();
const startDate = ref(new Date(today));
const endDate = ref(new Date(today));
const customRange = ref(false);
const chartData = ref<TimeSeriesChartData | null>({ xAxis: [], yAxis: [] });
const chartLoading = ref(true);

// Lifecycle hooks
onMounted(async () => {
  intervalId = setInterval(
    async () => await updateStatisticValues(),
    updateInterval,
  );
  await updateStatisticValues();
  // valid.value;
});

onBeforeUnmount(() => {
  if (intervalId !== -1) {
    clearInterval(intervalId);
  }
});

watch(
  () => [terminalStore.terminal, forwarderStore.forwarder],
  async ([newTerminal, newForwarder], [oldTerminal, oldForwarder]) => {
    if (
      newTerminal?.id !== oldTerminal?.id ||
      newForwarder?.id !== oldForwarder?.id
    ) {
      await updateStatisticValues();
    }
  },
);

// Methods
const filteredServices = computed(
  () =>
    groupedItems.value[0].services?.filter(
      service => service.name.toLowerCase() !== "dashboard",
    ) || [],
);

const getCurrentTimeSlot = (id?: number) => {
  const mod = terminalData.value.modules.find(mod => mod.id === id);
  const now = new Date();
  const current = mod?.timeSlots?.find(slot => {
    const slotBegin = new Date(slot.slotBegin);
    const slotEnd = new Date(slot.slotEnd);
    return now >= slotBegin && now < slotEnd;
  });

  return current;
};

const currentCapacity = (id?: number) => {
  const slot = getCurrentTimeSlot(id);
  return slot
    ? `${slot.capacity.currentNumber} / ${slot.capacity.maximum}`
    : `-/-`;
};

const getCurrentTimeSlotColor = (moduleId: number) => {
  const timeSlot = getCurrentTimeSlot(moduleId);
  console.log(timeSlot, moduleId);
  return timeSlot ? timeSlot.category : "grey";
};

const getCurrentTimeSlotTime = (id?: number) => {
  const slot = getCurrentTimeSlot(id);
  if (slot) {
    return `${format(new Date(slot.slotBegin), "HH:mm")} - ${format(
      new Date(slot.slotEnd),
      "HH:mm",
    )}`;
  }
  return "-/-";
};

const getCapacityWidth = (id: number) => {
  const slot = getCurrentTimeSlot(id);
  if (!slot) return "0%";

  const percentage =
    (slot.capacity.currentNumber / slot.capacity.maximum) * 100;
  return `${percentage}%`;
};

const formatDateInput = (date: Date) => format(date, "dd.MM.yyyy");

const allowedDates = (date: unknown) => {
  const today = new Date();
  if (date instanceof Date) {
    return compareAsc(today, date) > 0; // if date is in the past
  }
  return false;
};

const startDateChange = (): void => {
  customRange.value = true;
  if (!validate()) return;
  if (compareAsc(startDate.value, endDate.value) > 0) {
    endDate.value = new Date(startDate.value);
  }
  updateStatisticValues();
};

const endDateChange = (): void => {
  customRange.value = true;
  if (!validate()) return;
  if (compareAsc(startDate.value, endDate.value) > 0) {
    startDate.value = new Date(endDate.value);
  }
  updateStatisticValues();
};

const dateNotEmpty = (date: Date) =>
  (!!date && date != null) || i18n.global.t("fieldRequired");

const validate = (): boolean => valid.value;

const updateStatisticValues = async (): Promise<void> => {
  const responses = await Promise.all(
    customRange.value
      ? statisticNames.map(e =>
          viewModel.fetchDataForStatisticBox(
            pickCustomFloatStatisticRequest(e),
          ),
        )
      : statisticNames.map(e =>
          viewModel.fetchDataForStatisticBox(
            pickFloatStatisticRequest(range.value, e),
          ),
        ),
  );

  const result: Record<string, StatisticValue> = {};
  responses.forEach(statistic => {
    const parsedResponse = parseFloatResponse(statistic);
    if (parsedResponse) {
      result[statistic.name] = parsedResponse;
    }
  });
  statisticValues.value = result;

  chartLoading.value = true;
  await fetchData();
  chartLoading.value = false;
};

const fetchData = async (): Promise<void> => {
  let response: TimeSeriesResponse;
  if (startDate.value && endDate.value) {
    response = await viewModel.fetchTimeSeriesChartDataForCustomRange(
      startDate.value.toDateString(),
      endDate.value.toDateString(),
    );
  } else {
    response = await viewModel.fetchTimeSeriesChartData(range.value);
  }
  chartData.value = { xAxis: response.xAxis, yAxis: response.yAxis };
};

const pickFloatStatisticRequest = (
  range: TimeRange,
  type: string,
): RangedTerminalStatisticsValueRequest => {
  const floatStatistic = viewModel.pickFloatStatistic(
    range.duration,
    range.amount,
    type,
  );
  startDate.value = parseISO(floatStatistic.start);
  endDate.value = parseISO(floatStatistic.end);
  return floatStatistic;
};

const pickCustomFloatStatisticRequest = (
  type: string,
): RangedTerminalStatisticsValueRequest => ({
  start: startOfDayIso(startDate.value),
  end: endOfDayIso(endDate.value),
  name: getStatisticTypeFromStringForTerminal(type),
});

const parseFloatResponse = (
  response: RangedTerminalStatisticsValueResponse,
): StatisticValue => {
  return {
    color: findBorderColor(response),
    title: response.name ?? "",
    unit: response.unit ?? "",
    value: response.floatValue ?? 0,
  };
};

const changeRange = async (newRange: TimeRange): Promise<void> => {
  customRange.value = false;
  range.value = newRange;
  endDate.value = new Date();
  startDate.value = new Date();
  await updateStatisticValues();
};

const startOfDayIso = (date: Date): string => {
  const startDate = startOfDay(date);
  return startDate.toISOString();
};

const endOfDayIso = (date: Date): string => {
  const endDate = endOfDay(date);
  return endDate.toISOString();
};

// Computed properties
const minDate = computed(() => {
  const twoYearsBeforeEndDate = sub(endDate.value, { years: 2 });
  return formatISO(twoYearsBeforeEndDate);
});

const startDatePlaceHolder = computed(
  () => i18n.global.t("startDate") as string,
);

const endDatePlaceHolder = computed(() => i18n.global.t("endDate") as string);
</script>

<style scoped>
.capacity-text {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -48%);
  width: 4em;
}
</style>
