<template>
  <v-row>
    <v-col cols="4" md="6" class="d-flex justify-center">
      <div style="max-width: 70%" class="text-left">
        <div class="text-h5 font-weight-bold">
          {{ t("slotManagement.settings.time_slot_capacity") }}
        </div>
        <div class="pt-2">
          {{ t("slotManagement.settings.time_slot_capacity_description") }}
        </div>
      </div>
    </v-col>
    <v-col cols="8" md="6">
      <v-card class="py-5">
        <div>
          <v-select
            v-model="slotLength"
            density="compact"
            variant="outlined"
            class="mx-5"
            hide-details
            :label="t('slotManagement.settings.slot_length')"
            :items="slotOptions"
            item-value="value"
            item-title="text"
          />
        </div>
        <div class="text-h6 text-left mx-5 py-2">
          {{ t("slotManagement.settings.capacity_per_hour") }}
        </div>
        <div v-for="module in terminalData.modules" :key="module.id">
          <v-number-input
            density="compact"
            variant="outlined"
            class="mx-5 py-2"
            inset
            hide-details
            :label="module.name"
            :min="0"
            :reverse="false"
            :model-value="module.slotLength"
            @update:model-value="value => validateWholeNumber(value, module)"
          ></v-number-input>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts" setup>
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import data from "@/data/temporary-terminal-mock-data.json";
import TerminalData from "@/internal-models/terminalMockData";

const terminalData = ref<TerminalData>(data);

const props = defineProps({
  originalSlotLength: {
    type: Number,
    required: true,
  },
});

const { t } = useI18n();
const emit = defineEmits(["stateChange"]);

const slotLength = ref(30);

const slotOptions = [
  { value: 30, text: "30 min" },
  { value: 60, text: "60 min" },
  { value: 120, text: "120 min" },
];

const validateWholeNumber = (
  value: number,
  module: TerminalData["modules"][number],
) => {
  const validValue = Number.isInteger(value) ? value : Math.floor(value) || 0;
  module.slotLength = validValue;
  emit("stateChange", isDataDirty());
};

const isDataDirty = () => {
  return terminalData.value.modules.some(
    module => module.slotLength !== props.originalSlotLength,
  );
};

watch(slotLength, newValue => {
  const isDirty = newValue !== props.originalSlotLength;
  emit("stateChange", isDirty);
});
</script>
