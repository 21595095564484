<template>
  <v-select
    v-model="selectedItem"
    density="comfortable"
    hide-details="auto"
    bg-color="white"
    variant="outlined"
    :items="items"
    :label="$t('selectTime')"
    :no-data-text="$t('no_data_available')"
    @update:modelValue="handleModelValueUpdate"
  />
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import dropdownRanges from "@/constants/dropdown-ranges";
import TimeRange from "@/internal-models/time-range";
import DropdownItem from "@/internal-models/dropdown-item";

const props = defineProps({
  customRange: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(["changeRange"]);
const { t, locale } = useI18n();

const items = ref<Array<DropdownItem>>([]);
const selectedItem = ref<DropdownItem | null>(null);

const dropdownText = (item: TimeRange): string => {
  return `${item.amount} ${t(item.duration)}`;
};

const changeRange = (item: DropdownItem): void => {
  if (typeof item.value !== "string") {
    emit("changeRange", item);
  }
};

const createDropdownItems = (): Array<DropdownItem> => {
  return dropdownRanges.map(e => ({
    title: dropdownText(e),
    value: e,
  }));
};

const handleModelValueUpdate = (item: DropdownItem): void => {
  selectedItem.value = item;
  changeRange(item);
};

onMounted(() => {
  items.value = createDropdownItems();
  items.value.push({ title: t("custom"), value: "custom" });
  selectedItem.value = items.value[0];
});

watch(
  () => props.customRange,
  newVal => {
    if (newVal) {
      selectedItem.value = items.value[items.value.length - 1];
    }
  },
);

watch(
  () => locale.value,
  () => {
    const previousSelection = selectedItem.value?.value;
    items.value = createDropdownItems();
    items.value.push({ title: t("custom"), value: "custom" });

    selectedItem.value =
      items.value.find(item => item.value === previousSelection) ||
      items.value[0];
  },
);
</script>

<style scoped></style>
