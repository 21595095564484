<template>
  <div>
    <TheMainHeader
      :text="forwarderStore.forwarder?.name ?? t('missing')"
      class="text-left pl-10 pt-10"
      data-testid="test-dashboardTextForwarderWebapp"
    />
    <div class="d-flex text-left pl-10 pt-2 align-center">
      <span class="bg-greyish pa-2 rounded-lg">
        <v-icon
          :icon="`custom:${groupedItems[2]?.services?.[0]?.icon}`"
          :height="24"
          :width="24"
        />
      </span>
      <span class="text-h4 font-weight-medium text-primary ml-2">
        {{ t(groupedItems[2]?.services?.[0]?.name ?? "missing") }}
      </span>
    </div>

    <div>
      <v-container fluid class="pt-10 pa-10">
        <v-row>
          <v-col cols="12" md="7" lg="6">
            <v-row>
              <v-col cols="12" class="text-h5 font-weight-bold">
                <div class="text-left">{{ t("statistics") }}</div>
              </v-col>

              <v-col xs="6" sm="4" cols="6">
                <TheStatisticValueBox
                  class="click"
                  @click="goToBookingsOverview"
                  :statistic-value="
                    state.statisticValues[state.statisticNames[0]]
                  "
                  data-testid="test-openOrdersStatisticValueBox"
                />
              </v-col>

              <v-col xs="6" sm="4" cols="6">
                <TheStatisticValueBox
                  class="click"
                  @click="goToTruckerManagement"
                  :statistic-value="
                    state.statisticValues[state.statisticNames[1]]
                  "
                  data-testid="test-registeredDriversStatisticValueBox"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col xs="6" sm="12" lg="4">
            <v-row>
              <div class="text-h5 font-weight-bold my-4">{{ t("apps") }}</div>
              <div
                v-for="service in filteredServices"
                :key="service.name"
                class="w-100 bg-white rounded-lg mb-2 border"
              >
                <v-list-item
                  class="rounded-lg cursor-pointer pl-3"
                  :to="service.pagePath"
                  :replace="true"
                  style="height: 4em"
                >
                  <template #prepend>
                    <div class="mr-2 bg-greyish pa-2 rounded-lg">
                      <v-icon
                        :icon="`custom:${service.icon}`"
                        :height="24"
                        :width="24"
                      />
                    </div>
                  </template>

                  <v-list-item-title
                    class="font-weight-medium text-left text-h6"
                  >
                    {{ t(service.name) }}
                  </v-list-item-title>

                  <template #append>
                    <v-icon
                      icon="mdi-chevron-right"
                      color="primary"
                      :height="24"
                      :width="24"
                    />
                  </template>
                </v-list-item>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  onMounted,
  onBeforeUnmount,
  reactive,
  watch,
  ref,
  computed,
} from "vue";
import TheMainHeader from "@/components/TheMainHeader.vue";
import TheStatisticValueBox from "@/components/TheStatisticValueBox.vue";
import StatisticValue from "@/internal-models/statistic-value";
import {
  ConstantForwarderStatisticFloatValueResponseTO,
  ConstantForwarderStatisticValueRequestTO,
} from "@/services/client/generated";
import { findBorderColor } from "@/utils/statistic-utils";
import { useRouter } from "vue-router";
import { getViewModel } from "./forwarder-dashboard-logic";
import { useForwarderStore } from "@/store/useForwarderStore";
import { useI18n } from "vue-i18n";
import getSidebarItems from "@/constants/sidebar-items";

const router = useRouter();
const viewModel = getViewModel();
const state = reactive({
  forwarderOperatorPageCards: {},
  statisticValues: {} as Record<string, StatisticValue>,
  statisticNames: ["open_orders", "registered_truckers"],
  intervalId: -1,
  updateInterval: 1000 * 60,
});
const { t } = useI18n();

const groupedItems = ref(getSidebarItems());
const forwarderStore = useForwarderStore();

const goToBookingsOverview = () => {
  router.push("/forwarderOperator/bookingsOverview");
};

const goToTruckerManagement = () => {
  router.push("/forwarderOperator/truckerManagement");
};

const updateStatisticValues = async () => {
  const results =
    await Promise.all<ConstantForwarderStatisticFloatValueResponseTO>(
      state.statisticNames.map(e =>
        viewModel.fetchDataForStatisticBox(pickFloatStatisticRequest(e)),
      ),
    );

  const result: Record<string, StatisticValue> = {};
  results.forEach(statistic => {
    const parsedResponse = parseFloatResponse(statistic);
    if (parsedResponse) {
      result[statistic.name] = parsedResponse;
    }
  });

  state.statisticValues = result;
};

const pickFloatStatisticRequest = (
  type: string,
): ConstantForwarderStatisticValueRequestTO => {
  return viewModel.pickFloatStatistic(type);
};

const parseFloatResponse = (
  response: ConstantForwarderStatisticFloatValueResponseTO,
): StatisticValue | null => {
  if (!response) return null;
  return {
    color: findBorderColor(response),
    title: response.name,
    unit: response.unit,
    value: response.floatValue,
  };
};

const filteredServices = computed(
  () =>
    groupedItems.value[2].services?.filter(
      service => service.name.toLowerCase() !== "dashboard",
    ) || [],
);

onMounted(async () => {
  state.intervalId = window.setInterval(async () => {
    await updateStatisticValues();
  }, state.updateInterval);
  await updateStatisticValues();
});

onBeforeUnmount(() => {
  if (state.intervalId !== -1) {
    clearInterval(state.intervalId);
  }
});

watch(
  () => forwarderStore.forwarder,
  async (newForwarder, oldForwarder) => {
    if (newForwarder?.id !== oldForwarder?.id) {
      await updateStatisticValues();
    }
  },
);
</script>

<style lang="scss" scoped>
.click {
  cursor: pointer;
}
</style>
