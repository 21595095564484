<template>
  <div>
    <TheMainHeader class="text-left pl-10 pt-10" :text="terminalName ?? ''" />
    <div class="pl-10 text-left text-medium-emphasis">
      {{ t("bookingOverview") }}
    </div>
    <div>
      <v-container fluid class="pa-10">
        <VirtualTable
          :items="sortedBookings"
          :headers="headers"
          :key="sortedBookings.length"
          @load-more="handleLoadMore"
        >
          <template #item.led="{ value }">
            <span
              :class="[
                'rounded-circle d-inline-block led-size',
                getLedColor(
                  value.state ?? TerminalOperatorBookingTOStateEnum.Active,
                ),
              ]"
            />
          </template>
          <template #item.type="{ value }">
            <span>{{ t(value.type ?? "") }}</span>
          </template>
          <template #item.date="{ value }">
            <span>{{ formatDate(value.timeSlot?.slotBegin) }}</span>
          </template>
          <template #item.time_slot="{ value }">
            <span>
              {{ formatTime(value.timeSlot?.slotBegin) }} -
              {{ formatTime(value.timeSlot?.slotEnd) }}
            </span>
          </template>
          <template #item.state="{ value }">
            <span>{{ t(value.state ?? t("statusNotFound")) }}</span>
          </template>
          <template #item.actions="{ value }">
            <v-btn
              color="primary"
              :disabled="
                value.state === TerminalOperatorBookingTOStateEnum.Completed
              "
              @click="rerouteToParking(value)"
            >
              {{ t("rerouteToParking") }}
            </v-btn>
          </template>
        </VirtualTable>
      </v-container>
    </div>
  </div>
</template>

<script setup lang="ts">
import TheMainHeader from "@/components/TheMainHeader.vue";
import { TerminalOperatorBookingTOStateEnum } from "@/services/client/generated";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useTerminalBookingOverviewLogic } from "./terminal-booking-overview-logic";
import VirtualTable from "@/components/virtual-table/VirtualTable.vue";

const { t } = useI18n();
const {
  sortedBookings,
  formatTime,
  formatDate,
  getLedColor,
  sortDateRaw,
  rerouteToParking,
  sortStatusRaw,
  handleLoadMore,
  terminalName,
} = useTerminalBookingOverviewLogic();

const headers = computed(
  () =>
    [
      { title: "", key: "led", value: "led", width: "1em", sortable: false },
      {
        title: t("type"),
        key: "type",
        value: "type",
        align: "center",
        sortable: false,
      },
      {
        title: t("reference_number"),
        key: "referenceNumber",
        value: "referenceNumber",
        align: "center",
        sortable: false,
      },
      {
        title: t("containerNumber"),
        key: "containerNumber",
        value: "containerNumber",
        align: "center",
        sortable: false,
      },
      {
        title: t("date"),
        key: "date",
        value: "date",
        align: "center",
        sortRaw: sortDateRaw,
      },
      {
        title: t("timeSlot"),
        key: "time_slot",
        value: "time_slot",
        align: "center",
        sortable: false,
      },
      {
        title: t("status"),
        key: "state",
        value: "state",
        align: "center",
        width: "30em",
        sortable: true,
        sortRaw: sortStatusRaw,
      },
      {
        title: "",
        key: "actions",
        value: "actions",
        width: "8em",
        sortable: false,
      },
    ] as const,
);
</script>

<style scoped>
.led-size {
  width: 2em;
  height: 2em;
}
</style>
