<template>
  <div>
    <div v-if="loading">
      <LoadingIndicator />
    </div>

    <v-card
      v-else
      min-height="300"
      class="d-flex flex-column rounded-lg"
      variant="flat"
      border="opacity-10 sm"
    >
      <v-card-item style="height: 100px">
        <v-tooltip :text="$t(statisticValue.title)" location="top">
          <template #activator="{ props: activeProps }">
            <div v-bind="activeProps" class="pa-2 text-h6 text-truncate">
              <span
                v-if="
                  statisticValue.title === 'time_register_success_transhipment'
                "
              >
                {{ $t("waiting_time") }}
              </span>
              <span v-else>{{ $t(statisticValue.title) }}</span>
            </div>
          </template>
        </v-tooltip>
      </v-card-item>

      <div
        class="text-h4 d-flex justify-center align-center font-weight-bold"
        style="flex: 1"
      >
        <span
          style="height: 0.7em; width: 0.7em"
          class="rounded-circle mx-2"
          :class="`bg-${statisticValue.color}`"
        />
        {{ parsedStatisticValue }}
      </div>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import StatisticValue from "@/internal-models/statistic-value";
import { getViewModel } from "@/views/terminal-operator/dashboard/terminal-dashboard-logic";

type FontSize = "default" | "small";
const viewModel = getViewModel();
const props = defineProps<{
  statisticValue: StatisticValue;
  fontSize?: FontSize;
}>();

const normalizeValueIfTime = (value: number, unit: string): string => {
  if (unit === "min") {
    return viewModel.getMinutesForStatistic(value);
  }
  return value.toString();
};

const loading = computed((): boolean => {
  return !props.statisticValue;
});

const parsedStatisticValue = computed((): string => {
  if (!props.statisticValue) return "";
  return `${normalizeValueIfTime(
    props.statisticValue.value,
    props.statisticValue.unit,
  )}
  ${props.statisticValue.unit === "count" ? "" : props.statisticValue.unit}`;
});
</script>

<style lang="scss" scoped></style>
