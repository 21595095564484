<template>
  <v-row>
    <v-col cols="4" md="6" class="d-flex justify-center">
      <div style="max-width: 70%" class="text-left">
        <div class="text-h5 font-weight-bold">
          {{ t("slotManagement.settings.opening_hours") }}
        </div>
        <div class="pt-2">
          {{ t("slotManagement.settings.opening_hours_description") }}
        </div>
      </div>
    </v-col>

    <v-col cols="8" md="6">
      <v-card class="py-5">
        <div
          v-for="(day, index) in days"
          :key="index"
          class="d-flex align-center justify-center py-2"
        >
          <v-row align="center" justify="center">
            <v-col cols="2" class="text-left">{{ day.name }}</v-col>

            <v-col cols="4">
              <v-select
                :items="[...hours, 'Closed']"
                density="compact"
                variant="outlined"
                hide-details
                v-model="day.open"
              />
            </v-col>

            <v-col cols="1">to</v-col>

            <v-col cols="4">
              <v-select
                :items="[...hours, 'Closed']"
                density="compact"
                variant="outlined"
                hide-details
                v-model="day.close"
              />
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { ref, watch } from "vue";

const { t, locale } = useI18n();

const suffix = ref(locale.value === "de" ? "Uhr" : "o'clock");

const getLocalizedWeekdays = (lang: string) => {
  const formatter = new Intl.DateTimeFormat(lang, { weekday: "long" });
  const baseDate = new Date(2024, 0, 1);
  return Array.from({ length: 7 }, (_, i) =>
    formatter.format(new Date(baseDate.getTime() + i * 86400000)),
  );
};

const localizedWeekdays = ref(getLocalizedWeekdays(locale.value));

const days = ref(
  localizedWeekdays.value.map((name, index) => ({
    name,
    open: index >= 5 ? t("slotManagement.settings.closed") : "08:00 Uhr",
    close: index >= 5 ? t("slotManagement.settings.closed") : "08:00 Uhr",
  })),
);

watch(
  () => locale.value,
  newLang => {
    const updatedWeekdays = getLocalizedWeekdays(newLang);
    localizedWeekdays.value = updatedWeekdays;
    suffix.value = newLang === "de" ? "Uhr" : "o'clock";

    days.value = updatedWeekdays.map((name, index) => ({
      name,
      open:
        index >= 5
          ? t("slotManagement.settings.closed")
          : `08:00 ${suffix.value}`,
      close:
        index >= 5
          ? t("slotManagement.settings.closed")
          : `08:00 ${suffix.value}`,
    }));
    hours.value = Array.from({ length: 24 }, (_, i) => {
      const hour = i.toString().padStart(2, "0");
      return `${hour}:00 ${suffix.value}`;
    });
  },
);

const hours = ref(
  Array.from({ length: 24 }, (_, i) => {
    const hour = i.toString().padStart(2, "0");
    return `${hour}:00 ${suffix.value}`;
  }),
);
</script>
